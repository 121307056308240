/** @format */

import React from "react";
// import Accordion from "@material-ui/core/Accordion";
// import AccordionSummary from "@material-ui/core/AccordionSummary";
// import AccordionDetails from "@material-ui/core/AccordionDetails";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
//                              Accordion
// ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
const Accordions = () => {
  return (
    <>
      <div className='container mt-5'>
        <div className='accordion' id='accordionExample'>
          {/* 1 */}

          <div className='accordion-item'>
            <button
              className='accordion-button accordion-btn collapsed'
              data-bs-toggle='collapse'
              data-bs-target='#collapse1'
              aria-expanded='false'
              aria-controls='collapseTwo'>
              <div>
                Round 1 is live, participate now!
                <span className='d-block' style={{ fontSize: "10pt" }}>
                  Start date: 25th May <br />
                  Ends date: 15th June
                </span>
              </div>
            </button>
            <div
              id='collapse1'
              className='accordion-collapse collapse'
              aria-labelledby='headingTwo'
              data-bs-parent='#accordionExample'>
              <div className='accordion-body'>
                <ul>
                  <li>
                    <strong>Total reward: </strong> $25000
                    <ul>
                      <li>$5000 Top 416 referrers ($12/each)</li>
                      <li>$20,000 Random 28571 participants ($7/each)</li>
                    </ul>
                  </li>
                  <li>
                    <strong>Tasks: </strong> $5000
                    <ul>
                      <li>
                        <strong>Join the Telegram group</strong>: &nbsp;
                        <a target='_BLANK' href='https://t.me/Secret_pep'>
                          @Secret_pep (https://t.me/Secret_pep)
                        </a>
                      </li>
                      <li>
                        <strong>Follow Twitter</strong>: &nbsp;
                        <a
                          target='_BLANK'
                          href='https://twitter.com/SecretPep_'>
                          (https://twitter.com/SecretPep_)
                        </a>
                      </li>
                      <li>Retweet the pinned post.</li>
                      {/* <li><strong>Join Facebook Group</strong>:
                      &nbsp;
                        <a target="_BLANK" href="https://www.facebook.com/groups">
                          (https://www.facebook.com/groups/)
                        </a>
                      </li> */}
                      <li>Comment on the first post and use hashtag #SecretPEP</li>
                      <li>
                        <strong>Join Discord Channel</strong>: &nbsp;
                        <a target='_BLANK' href='https://discord.gg/SUnBNkhvRc'>
                          (https://discord.gg/SUnBNkhvRc)
                        </a>
                      </li>
                      {/* <li><strong>Subscribe to YouTube Channel</strong>:
                      &nbsp;
                        <a target="_BLANK" href="()">
                          ()
                        </a>
                       and comment on explainer video.</li> */}
                      <li>
                        <strong>Up Vote and Comment on Reddit Post</strong>:
                        &nbsp;
                        <a
                          target='_BLANK'
                          href='https://www.reddit.com/r/Secret_Pep'>
                          https://www.reddit.com/r/Secret_Pep
                        </a>
                      </li>
                      {/* <li><strong>Comment on Pinksale</strong>:
                      &nbsp;
                        <a target="_BLANK" href="">
                        </a>
                      </li> */}
                    </ul>
                  </li>
                </ul>
                <a
                  href='https://forms.gle/Vme2tFdkXZTw2BFWA'
                  className='submit-btn'>
                  Submit
                </a>
              </div>
            </div>
          </div>

          {/* 2 */}
          <div className='accordion-item'>
            <button
              className='accordion-button accordion-btn collapsed'
              data-bs-toggle='collapse'
              data-bs-target='#collapse2'
              aria-expanded='false'
              aria-controls='collapseTwo'>
              <div>
                Round 2 Starts on 20th June
                <span className='d-block' style={{ fontSize: "10pt" }}>
                  
                </span>
              </div>
            </button>
            <div
              id=''
              className='accordion-collapse collapse'
              aria-labelledby='headingTwo'
              data-bs-parent='#accordionExample'>
              <div className='accordion-body'>
                <ul>
                  <li>
                    <strong>Total reward: </strong> $3000
                    <ul>
                      <li>$2000 - Top 100 referrers ( $20/each)</li>
                      <li>$1000 - Random 200 participants ( $5/each)</li>
                    </ul>
                  </li>
                  <li>
                    <strong>Tasks: </strong>
                    <ul>
                      <li>
                        <strong>Retweet this post: </strong>
                      </li>
                      <li>
                        <strong>Post new tweet on your profile with:</strong>:
                        &nbsp;
                        <ul>
                          <li>
                            High APY staking platform is live:{" "}
                            <a
                              target='_BLANK'
                              href='https://staker.secretpep.com/'>
                              https://staker.secretpep.com/
                            </a>
                          </li>
                          <li>#BSC #Pancakeswap #Defi #Staking #SPEP</li>
                        </ul>
                      </li>
                      <li>#BSC #Pancakeswap #Defi #Staking #SPEP</li>
                    </ul>
                  </li>
                  <li>
                    <strong>Up Vote and Comment on Reddit Post: </strong>
                    <a
                      terget='_BLANK'
                      href='https://www.reddit.com/r/Secret_Pep'>
                      https://www.reddit.com/r/Secret_Pep
                    </a>
                  </li>
                </ul>
                <a className='submit-btn'>Submit</a>
              </div>
            </div>
          </div>

          {/* 3 */}
          <div className='accordion-item '>
            <button
              className='accordion-button accordion-btn collapsed '
              data-bs-toggle='collapse'
              data-bs-target='#collapse3'
              aria-expanded='true'
              aria-controls='collapseTwo'>
              <div>
                Round 3 Starts on 1st July
                <span className='d-block' style={{ fontSize: "10pt" }}>
                 
                </span>
              </div>
            </button>
            <div
              id=''
              className='accordion-collapse collapse '
              aria-labelledby='headingTwo'
              data-bs-parent='#accordionExample'>
              <div className='accordion-body'>
                <ul>
                  <li>
                    <strong>Total reward: </strong> $3000
                    <ul>
                      <li>$2000 - Top 100 referrers ( $20/each)</li>
                      <li>$1000 - Random 200 participants ( $5/each)</li>
                    </ul>
                  </li>
                  <li>
                    <strong>Tasks: </strong>
                    <ul>
                      <li>
                        <strong>Retweet this post: </strong>
                      </li>
                      <li>
                        <strong>Post new tweet on your profile with:</strong>:
                        &nbsp;
                        <ul>
                          <li>SPEP is going to list on ______</li>
                          <li>#BSC #Pancakeswap #Defi #Staking #CEX #SPEP</li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong>Up Vote and Comment on Reddit Post: </strong>
                    <a
                      terget='_BLANK'
                      href='https://www.reddit.com/r/Secret_Pep'>
                      https://www.reddit.com/r/Secret_Pep
                    </a>
                  </li>
                </ul>
                <a className='submit-btn'>Submit</a>
              </div>
            </div>
          </div>

          {/* 4 */}
          {/* <div className='accordion-item '>
            <button
              className='accordion-button accordion-btn collapsed'
              data-bs-toggle='collapse'
              data-bs-target='#collapse4'
              aria-expanded='false'
              aria-controls='collapse4'>
              <div>
                Round 4 starts in 11 days
                <span className='d-block' style={{ fontSize: "10pt" }}>
                  ends on June 19th, 2023 18:00
                </span>
              </div>
            </button>
            <div
              id='collapse4'
              className='accordion-collapse collapse '
              aria-labelledby='headingTwo'
              data-bs-parent='#accordionExample'>
              <div className='accordion-body '>
                <ul>
                  <li>
                    <strong>Total reward: </strong> $2000
                    <ul>
                      <li>$1000 - Top 100 referrers ( $10/each)</li>
                      <li>$1000 - Random 200 participants ( $5/each)</li>
                    </ul>
                  </li>
                  <li>
                    <strong>Tasks: </strong>
                    <ul>
                      <li>
                        <strong>Retweet this post: </strong>
                      </li>
                      <li>
                        <strong>Post new tweet on your profile with:</strong>:
                        &nbsp;
                        <ul>
                          <li>SPEP Governance platform is going live _____</li>
                          <li>
                            #BSC #Pancakeswap #Defi #Staking #CEX #Governance
                            #SPEP
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong>Up Vote and Comment on Reddit Post: </strong>
                    <a
                      terget='_BLANK'
                      href='https://www.reddit.com/r/Secret_Pep'>
                      https://www.reddit.com/r/Secret_Pep
                    </a>
                  </li>
                </ul>
                <a className='submit-btn'>Submit</a>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Accordions;
