/** @format */

import React from "react";

// ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
//                              Heading
// ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
const Heading = () => {
  return (
    <>
      <div className='container pt-5 heading'>
        <div className='row text-center'>
          <div className='col-sm-12'>
            <h1>SecretPEP AirDrop 2023</h1>
            <h4>
              Maximize your winning by participating in our $25k worth of
              airdrop reward campaign!
            </h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default Heading;
