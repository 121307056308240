/** @format */

import "./App.css";
import Navbar from "./Components/Navbar";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"; // import bootstrap css
import "../node_modules/bootstrap/dist/js/bootstrap.bundle"; // import bootstrap js bundle
import Heading from "./Components/Heading";
import Accordion from "./Components/Accordion";
import ReactGA from 'react-ga';
ReactGA.initialize('UA-269597103-1');
ReactGA.pageview(window.location.pathname + window.location.search);
function App() {
  return (
    <>
      <Navbar />
      <Heading />
      <Accordion />
    </>
  );
}

export default App;
