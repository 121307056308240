/** @format */

import React from "react";
// ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
//                              Navbar
// ======================°°°°°°°°°°°°°°°°°°°°°°°=========================

const Navbar = () => {
  return (
    <>
      <nav className='navbar navbar-expand-lg navbar-dark sticky-top'>
        <div className='container'>
          <span className='navbar-brand'>
            <img src="logo.png" alt='..' width='180' />
          </span>
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarText'
            aria-controls='navbarText'
            aria-expanded='false'
            aria-label='Toggle navigation'>
            <span className='navbar-toggler-icon' />
          </button>
          <div className='collapse navbar-collapse ' id='navbarText'>
            <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
              <li className='nav-item'>
                <a href="https://secretpep.com/#stealth-wallet" target="_blank" rel="noReferrer" className='nav-link'>
                  Stealth Wallet<sup>:SPEP</sup>
                </a>
              </li>
              <li className='nav-item'>
                <a href="https://secretpep.com/#stealth-chat" target="_blank" rel="noReferrer" className='nav-link'>
                  Stealth Chat<sup>:SPEP</sup>
                </a>
              </li>
              <li className='nav-item'>
                <a href="https://secretpep.com/#SPEP-token" target="_blank" rel="noReferrer" className='nav-link'>SPEP Token</a>
              </li>
           
              <li className='nav-item'>
                <a href="https://t.me/Secret_pep" target="_blank" rel="noReferrer" className='btn nav-btn'>Join Telegram</a>
              </li>
            </ul>
          </div>
          <a href="https://t.me/Secret_pep" target="_blank" rel="noReferrer" className='btn nav-btn-m'>Join Telegram</a>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
